import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function BirlaReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Birla Estates Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">We as a company, are always evolving and exploring new dimensions. Birla Estates takes forward the Birla legacy of excellence in real estate takes forward the Birla legacy of excellence in real estate with life-designed homes and workspaces built on the values of trust and transparency. </p>
                <p className="AboutExpoPara">Birla Estates Private Limited is a 100 percent wholly owned subsidiary of Century Textiles and Industries Limited, the flagship Company of the B. K. Birla Group of Companies with a presence in textiles, rayon yarn, pulp & paper, salt, chemicals, and more. Birla Estates will be developing many land parcels held by the group as well as looking at joint development/strategic tie-ups across top cities.</p>

            </p>

            {isShowMore && (
                
                 <p className="mb-0">
                                    <h6>Our Vision</h6>
                <p className="AboutExpoPara">We will transform the perception of the Indian Real Estate sector by delivering an exceptional experience and creating value, at every level, for every stakeholder.
</p>
                    <h6>Our Mission</h6>
                <p className="AboutExpoPara">We will provide an iconic brand experience aligned with our legacy by never compromising on transparency, commitment, quality, and superior design.
</p>
                <h6>Our Mission</h6>
                <p className="AboutExpoPara">Trust and transparency are the values that Birla's legacy is built on. Values are the driving force behind everything that we do.</p>




            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default BirlaReadMore;